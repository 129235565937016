
import Vue from "vue"
import { SiteKeyUsers } from "@/models/models"

export default Vue.extend({
  name: "UserListExample",
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectUser: {
      type: Function,
      required: false,
      default: () => null,
    },
    selectedUser: {
      type: SiteKeyUsers,
      required: false,
      default: () => null,
    },
  },
  computed: {
    usersList(): SiteKeyUsers[] | null {
      const { siteKeyUsers } = this.$store.state.firetableModule
      return siteKeyUsers ?? []
    },
  },
  watch: {
    usersList() {
      return
    },
  },
  methods: {
    isSelectedUser(uid): boolean {
      return this.selectedUser?.id === uid
    },
  },
})
