
import Vue from "vue"
import { mapState } from "vuex"
import "firebase/firestore"
import "firebase/functions"

// Local
import UserListExample from "@/components/UserListExample.vue"
import { SiteKeyCompany, SiteKeyUsers } from "@/models/models"
import { Tasks } from "@/models/task"
import { db } from "@/firebase-init"
import { Util } from "@/helpers"
import ScheduleBuilderTaskCard from "@/views/CraftRecord/ScheduleBuilderTaskCard.vue"

interface DataParams {
  usersList: SiteKeyUsers[]
  tasksList: Record<string, Tasks[]>
  loadingTasks: boolean
  selectedUser: SiteKeyUsers | null
  companies: SiteKeyCompany[]
  theme: string
}

export default Vue.extend({
  name: "AssignedTasksLayout",
  components: { ScheduleBuilderTaskCard, UserListExample },
  data(): DataParams {
    return {
      usersList: [],
      tasksList: {},
      loadingTasks: false,
      selectedUser: null,
      companies: [],
      theme: process.env.VUE_APP_THEME,
    }
  },
  watch: {
    scheduledAwaitingTaskList() {
      this.getTasks()
    },
  },
  mounted() {
    this.getCompanies()
    this.loadTasks()
  },
  beforeDestroy(): void {
    this.$store.commit("firetableModule/removeScheduledAwaitingTaskList")
  },
  computed: {
    ...mapState("firetableModule", ["rootUserData"]),
    defaultSiteKey() {
      return this.rootUserData?.defaultSiteKey
    },
    scheduledAwaitingTaskList(): Tasks[] | null {
      return this.$store.state.firetableModule.scheduledAwaitingTaskList
    },
  },
  methods: {
    clearSelectedUser() {
      this.loadingTasks = true
      this.tasksList = {}
      this.selectedUser = null
      this.getTasks()
    },
    userSelected(user) {
      this.selectedUser = user
      this.getTasks()
    },

    loadTasks() {
      this.$store.dispatch("firetableModule/listenScheduledAwaitingTaskList")
      this.getTasks()
    },

    async getCompanies() {
      const { rootUserData, siteKeyUserPermissionData, craftRecord } =
        this.$store.state.firetableModule
      if (!rootUserData || !siteKeyUserPermissionData || !craftRecord) return
      this.companies = await Util.getSiteKeyCompanies({
        db,
        defaultSiteKey: rootUserData.defaultSiteKey,
        userPermissions: siteKeyUserPermissionData,
      })
      this.companies = this.companies.filter((record: SiteKeyCompany) =>
        record.craftTypes.includes(parseInt(craftRecord.craftType, 10))
      )
    },

    async getTasks(): Promise<void> {
      this.loadingTasks = true
      try {
        let allTasks = []

        allTasks = this.scheduledAwaitingTaskList

        this.tasksList = {}
        if (allTasks) {
          // Sort by timestamp scheduled with fancy date string
          allTasks.forEach((task) => {
            // If there is a selected user, then we should only show tasks where
            // they have been assigned to a task
            if (this.selectedUser != null) {
              if ("assignedTo" in task.taskSpecificDetails) {
                if (
                  !task.taskSpecificDetails.assignedTo.includes(
                    this.selectedUser.id
                  )
                ) {
                  return
                }
              } else {
                return
              }
            }
            const timestampDate = task.timestampScheduled?.toDate()
            if (timestampDate) {
              const timestampString =
                timestampDate.getFullYear() +
                "-" +
                ("0" + (timestampDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + timestampDate.getDate()).slice(-2) +
                " / " +
                timestampDate.toLocaleString("en-us", { weekday: "long" })
              if (Object.keys(this.tasksList).includes(timestampString)) {
                this.tasksList[timestampString].push(task)
              } else {
                this.tasksList[timestampString] = [task]
              }
            }
          })
        }
      } finally {
        this.loadingTasks = false
      }
    },
  },
})
